@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

/* .bg-ios-light-surface {
  --tw-bg-opacity: 1;
  background-color: #8f6548;
} */
